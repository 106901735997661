<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/admin/home">{{ $t("Home") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{ $t("ApproveReports") }}</label>
    </div>

    <v-toolbar-title class="mt-3">اعتماد التقارير</v-toolbar-title>

    <v-container fluid>
      <v-row>
        <v-col md="3">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.report'),
            }"
          >
            <div class="mt-2">
              <label class="control-label">اسم التقرير</label>
            </div>
            <v-autocomplete
              class="d-block mt-2"
              name="report"
              data-vv-scope="addEditValidation"
              v-validate="'required'"
              :data-vv-as="$t('Report Name')"
              hide-details
              dense
              outlined
              v-model="addEditObj.report"
              item-value="id"
              item-text="name"
              :items="listReport"
              clearable
            ></v-autocomplete>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.report')"
            >
              {{ errors.first("addEditValidation.report") }}
            </div>
          </div>
        </v-col>

        <!-- <v-col md="2" class="mt-2" v-if="addEditObj.report == 4">
          <div
            class="form-group"
          >
            <label class="control-label">{{ $t("Page") }}</label>
            <div>
              <el-input
                type="number"
                min="1"
                name="page"
                class="form-control mt-2"
                style="width: 100%"
                v-model="addEditObj.page"
              ></el-input>
            </div>
          </div>
        </v-col> -->

        <v-col md="3" class="mt-2" v-if="addEditObj.report !== 4">
          <div
            class="form-group"
            :class="{
              'has-error': errors.has('addEditValidation.cooking_date'),
            }"
          >
            <label class="control-label">{{ $t("Date") }}</label>
            <div>
              <el-date-picker
                type="date"
                name="cooking_date"
                class="form-control mt-2"
                style="width: 100%"
                v-validate="'required'"
                data-vv-scope="addEditValidation"
                :data-vv-as="$t('Cooking Date')"
                v-model="addEditObj.date"
              ></el-date-picker>
            </div>
            <div
              class="help-block"
              v-if="errors.has('addEditValidation.cooking_date')"
            >
              {{ errors.first("addEditValidation.cooking_date") }}
            </div>
          </div>
        </v-col>

        <v-col md="4" class="mt-9">
          <el-button @click="approveReport" type="primary">اعتماد </el-button>
          <el-button
            v-if="addEditObj.report == 2"
            class="mx-2"
            @click="clearDailyCookingReport"
            type="danger"
            >تنظيف
          </el-button>
          <v-col v-if="showConfirmFinalReportButton" class="mx-2">
            <span>{{$t('إعتماد نهائي')}}</span>
            <v-switch   type="number" name="cost"
                      :data-vv-as="$t('Cost')"
                      hide-details dense outlined
                        v-model="confirmFinalReport"
            ></v-switch>
          </v-col>
        </v-col>


      </v-row>
      <v-row v-loading="loading" class="mt-5"></v-row>

      <v-row v-loading="loading2" class="mt-5"></v-row>
    </v-container>

    <v-dialog v-model="approveModal" width="40rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            تأكيد استبدال الاعتماد
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 style="color: #000; font-weight: bold">
            <span>يوجد اعتماد مسبق لهذا التاريخ، هل تريد الاستبدال؟ </span>
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="approveModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="approveData"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : [],
      addEditObj: {
        date: null,
        report: null,
        page: 1,
      },
      loading: false,
      loading2: false,
      approveModal: false,
      showConfirmFinalReportButton :false,
      confirmFinalReport :false,
      listReport: [
        {
          id: 1,
          name: this.$t("DelegateToday"),
        },
        {
          id: 2,
          name: this.$t("CookingTodayApprove(1)"),
        },
        {
          id: 3,
          name: this.$t("CookingTodayApprove(2)"),
        },
      ],
    };
  },
  watch:{
    "addEditObj.report"(id){
      // Check if the newReport is not null and its id is 3
       if (id && id == 3) {
        // Set showConfirmFinalReportButton to true if id is 3
        this.showConfirmFinalReportButton = true;
      } else {
        // Otherwise, set it to false
        this.showConfirmFinalReportButton = false;
      }

    }
  },
  methods: {
    downloadPDF() {},
    clearDailyCookingReport() {
      if (this.addEditObj.report == 2) {
        this.loading = true;
        let send = {
          date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
        };

        this.$store
          .dispatch(`approveReport/clear_daily_cooking_report`, send)
          .then((res) => {
            this.loading = false;
            this.notifySuccess("ClearCookingToday", res);
          })
          .catch((error) => {
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    approveReport() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) {
          return this.notifyCustomError("Error", "ValidationsErrors")
        }
        this.loading = true;
        if (this.addEditObj.report == 1) {
          let send = {
            date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
          };

          this.$store
            .dispatch(`approveReport/check_verified_data`, send)
            .then((res) => {
              this.loading = false;
              let valid = res.data.valid;
              if (valid) this.approveModal = true;
              else this.approveData();
            })
            .catch((error) => {
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else if (this.addEditObj.report == 2) {
          this.sendRequestWithoutCount();
        } 
        
        else if (this.addEditObj.report == 3) {
          this.sendRequest2WithoutCount();
        }
      });
    },

    sendRequestWithoutCount() {
      let send = {
        date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
      };

      this.$store
        .dispatch(`approveReport/validate_daily_cooking_report`, send)
        .then((res) => {
          this.loading = false;
          this.notifySuccess("CookingTodayApprove", res);

          // secondRequest
          this.$store
            .dispatch(
              `approveReport/second_validate_daily_cooking_report`,
              send
            )
            .then((res) => {
              this.loading = false;
              this.notifySuccess("CookingTodayApprove", res);
            })
            .catch((error) => {
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sendRequest2WithoutCount() {
      let send = {
        date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
      };

      let total_pages;
      let finishPage = 0; // Initialize the finishPage counter

      this.$store.dispatch(`approveReport/get_count_data`, send)
          .then(res => {
            total_pages = res.data.total_pages;
            const notifyAt = [0.10,0.15,0.25, 0.5, 0.6,0.7, 0.75, 0.9]; // Progress milestones at 25%, 50%, 75%, and 90%
            let nextNotifyIndex = 0; // To track which notification should be shown next

            for (let i = 1; i <= total_pages; i++) {
              let newSend = {
                date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
                confirmFinalReport: this.confirmFinalReport,
                page: i
              };

              this.$store.dispatch(`approveReport/validate_daily_cooking_report1`, newSend)
                  .then((res) => {
                    this.loading = false;

                    // Increment finishPage when a page is processed
                    finishPage++;

                    // Calculate the progress percentage
                    let progress = finishPage / total_pages;

                    // Show notification when reaching the next progress milestone
                    if (progress >= notifyAt[nextNotifyIndex]) {
                      this.customNotifySuccess(
                          `Progress`,
                          `Processing ${Math.round(progress * 100)}% complete`
                      );
                      nextNotifyIndex++;
                    }

                    // If all pages are processed, run the final dispatch
                    if (finishPage === total_pages) {
                      this.$store.dispatch(`approveReport/second_validate_daily_cooking_report`, send)
                          .then((res) => {
                            this.loading = false;
                            this.notifySuccess("CookingTodayApprove", res);
                          })
                          .catch((error) => {
                            this.loading = false;
                          })
                          .finally(() => {
                            this.loading = false;
                          });
                    }
                  })
                  .catch((error) => {
                    this.loading = false;
                  })
                  .finally(() => {
                    this.loading = false;
                  });
            }
          })
          .catch((error) => {
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
    },



    //   sendRequest2WithoutCount() {
    //   let send = {
    //     date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
    //   };
    //
    //   let total_pages;
    //
    //   this.$store.dispatch(`approveReport/get_count_data`, send).then(res => {
    //     for(let i=1; i<=res.data.total_pages; i++) {
    //       let newSend = {
    //         date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
    //         confirmFinalReport: this.confirmFinalReport,
    //         page: i
    //       }
    //       total_pages= res.data.total_pages;
    //
    //       let finishPage = 0;
    //
    //       this.$store.dispatch(`approveReport/validate_daily_cooking_report1`, newSend)
    //           .then((res) => {
    //             this.loading = false;
    //
    //             console.log(finishPage)
    //             finishPage++;
    //             if(i == total_pages) {
    //               // secondRequest
    //               this.$store.dispatch(`approveReport/second_validate_daily_cooking_report`, send)
    //                   .then((res) => {
    //                     this.loading = false;
    //                     this.notifySuccess("CookingTodayApprove", res);
    //                   })
    //                   .catch((error) => {
    //                     this.loading = false;
    //                   })
    //                   .finally(() => {
    //                     this.loading = false;
    //                   });
    //             }
    //           })
    //       console.log(finishPage)
    //       }
    //
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    //
    // },

    approveData() {
      this.loading2 = true;
      this.approveModal = false;
      let send = {
        date: moment(this.addEditObj.date).format("YYYY-MM-DD"),
      };
      this.$store
        .dispatch(`approveReport/verified_data`, send)
        .then(() => {
          this.loading2 = false;
          this.customNotifySuccess("اعتماد", "تم الاعتماد بنجاح");
        })
        .catch((error) => {
          this.loading2 = false;
          this.customNotifySuccess("اعتماد", "فشلت عملية الاعتماد");
        })
        .finally(() => {
          this.loading2 = false;
        });
    },
  },
};
</script>
